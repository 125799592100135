<template>
  <v-container>
    <v-row justify="center">
      <v-card class="ma-4" elevation="0" max-width="400px">
        <v-card-text>
          <div>{{ $nome }}</div>
          <p class="text-h4 text--primary">Contato</p>
        <!--   <p>NewText</p> -->
          <v-simple-table>
            <thead>
              <tr>
                <th colspan="2" class="text-center text-h6"><v-icon large>mdi-domain</v-icon></th>
                </tr>
            </thead>
            
            <tbody>
                <tr>
                  <td>
                    <v-text-field class="ma-2" label="Seu nome" append-icon="mdi-account">

                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="ma-2" label="Telefone" append-icon="mdi-whatsapp">
                    </v-text-field>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <v-text-field
                    label="E-mail"
                    title="Seu melhor e-mail."
                    append-icon="mdi-email">
                    </v-text-field>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <v-text-field
                    label="Empresa"
                    title="Informe o nome de sua empresa."
                    append-icon="mdi-domain">
                    </v-text-field>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <v-text-field
                    label="Seu site"
                    title="Seu site ou domínio na internet"
                    append-icon="mdi-web">
                    </v-text-field>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <v-text-field
                    label="Sua atividade"
                    title="Qual sua atividade econômica?"
                    append-icon="mdi-domain">
                    </v-text-field>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <v-textarea
                    class="ma-2"
                    no-resize
                    counter="150"
                    rows="5"
                    filed
                    label="Como podemos te ajudar?">

                    </v-textarea>
                  </td>
                </tr>
              </tbody>
          </v-simple-table>
          <div class="text--primary text-center mt-4">Retornaremos o contato em breve. Obrigado</div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="ma-1" text color="deep-purple accent-4"> Limpar </v-btn>
          <v-btn class="ma-1" text color="success accent-4"> Enviar </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "contatoView",
  data: () => ({}),
};
</script>
