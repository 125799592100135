<template>
  <v-app>
    <div app>
      <v-app-bar
      fixed
      color="light-blue" 
      height="150" 
      dark>
      <v-btn small text><v-icon>mdi-whatsapp</v-icon></v-btn>
        <v-spacer></v-spacer>
        <v-img
          class=""
          min-width="100"
          src="/img/router-logo.png"
          max-width="200"
        />
        <v-spacer></v-spacer>
        <v-btn small text><v-icon>mdi-phone</v-icon></v-btn>
      </v-app-bar>
      <v-divider></v-divider>
      <v-app-bar fixed style="margin-top: 150px!important;" height="50">
        <v-slide-group show-arrows v-model="model">
          <v-slide-item key="a">
            <v-btn small text @click="setHome(1)">Home</v-btn>
          </v-slide-item>
          <v-slide-item key="b">
            <v-btn small text @click="setHome(2)">Produtos</v-btn>
          </v-slide-item>
          <v-slide-item key="c">
            <v-btn small text @click="setHome(3)">Serviços</v-btn>
          </v-slide-item>
          <v-slide-item key="d">
            <v-btn small text @click="setHome(4)">Quem Somos</v-btn>
          </v-slide-item>
          <v-slide-item key="e">
            <v-btn small text @click="setHome(5)">Contato</v-btn>
          </v-slide-item>          
        </v-slide-group>
        
<!--         <v-spacer></v-spacer>
        
        <v-spacer></v-spacer>
        
        <v-spacer></v-spacer>
        
        <v-spacer></v-spacer>
       
        <v-spacer></v-spacer>
        
        <v-spacer></v-spacer> -->
      </v-app-bar>
    </div>
    <v-main style="margin-top: 200px!important;">
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    
  }),
  methods:{
    setHome(h){
      this.$home = h
      this.$root.$emit('mudar', h)

    }
  }
};
</script>
