<template>
  <v-container>
    <v-row class="text-center">


      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Bem vindo!
        </h1>

        <p class="subheading font-weight-regular">
          Estamos arrumando nossa casa e estamos ansiosos para recebê-lo melhor quando você voltar.
          <br>
          <a
            href="https://routertecnologia.com.br"
            link de contato target="_blank"
          >link inicial</a>
        </p>
      </v-col>

      <v-col
        class="mb-5"
        cols="12"
      >
        <h2 class="headline font-weight-bold mb-3">
        fimPagInicial
        </h2>

        <v-row justify="center">
          <a
            v-for="(next, i) in whatsNext"
            :key="i"
            :href="next.href"
            class="subheading mx-3"
            target="_blank"
          >
            {{ next.text }}
          </a>
        </v-row>
      </v-col>

      <v-col
        class="mb-5"
        cols="12"
      >
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'HelloWorld',

    data: () => ({
      ecosystem: [
        {
          text: 'vuetify-loader',
          href: 'https://github.com/vuetifyjs/vuetify-loader',
        },
        {
          text: 'github',
          href: 'https://github.com/vuetifyjs/vuetify',
        },
        {
          text: 'awesome-vuetify',
          href: 'https://github.com/vuetifyjs/awesome-vuetify',
        },
      ],
      importantLinks: [
        {
          text: 'Link 1',
          href: 'https://vuetifyjs.com',
        },
        {
          text: 'Link 2',
          href: 'https://community.vuetifyjs.com',
        },
        {
          text: 'Link 3',
          href: 'https://madewithvuejs.com/vuetify',
        },
        {
          text: 'Link 4',
          href: 'https://twitter.com/vuetifyjs',
        },
        {
          text: 'Articles',
          href: 'https://medium.com/vuetify',
        },
      ],
      whatsNext: [
        {
          text: 'Link 1',
          href: 'https://vuetifyjs.com/components/api-explorer',
        },
        {
          text: 'Link 2',
          href: 'https://vuetifyjs.com/getting-started/pre-made-layouts',
        },
        {
          text: 'Link 2',
          href: 'https://vuetifyjs.com/getting-started/frequently-asked-questions',
        },
      ],
    }),
  }
</script>
