<template>
  <v-container>
    <v-row justify="center">
      <v-card class="ma-4" elevation="0">
        <v-card-text>
          <div>{{ $nome }}</div>
          <p class="text-h4 text--primary">Produtos</p>
          <p>NewText</p>
          <div class="text--primary">Conteúdo de Produtos</div>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="deep-purple accent-4"> Botão / Ação </v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>
  <script>
export default {
  name: "produtosView",
  data: () => ({}),
};
</script>
  