<template>
  <div>
    <Transition >
      <Home v-if="home==1"/>
      <Produtos v-else-if="home==2"/>
      <Servicos v-else-if="home==3"/>
      <quem-somos v-else-if="home==4"/>
      <Contato v-else-if="home==5"/>
    </Transition>
  
</div>
</template>

<script>
  import QuemSomos from '@/components/QuemSomos.vue'
  import Home from '../components/Home'
  import Produtos from '../components/Produtos'
  import Servicos from '../components/Servicos'
  import Contato from '@/components/Contato.vue'


  export default {
    name: 'HomeView',
    data: () => ({
      home : 1
    }),
    components: {
      Home,
      Produtos,
      Servicos,
      QuemSomos,
      Contato
    },
    methods:{
       setView(v){
        this.home = v
       },

    },
    mounted(){
      this.$root.$on('mudar', (v) => this.setView(v))
    }
  }
</script>
<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
  opacity: 0;
}

.v-enter-from,
.v-leave-to {
  transition: opacity 0.5s ease;
  opacity: 0;
}
</style>